(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("better-sqlite3"));
	else if(typeof define === 'function' && define.amd)
		define(["better-sqlite3"], factory);
	else if(typeof exports === 'object')
		exports["dot-webformumat"] = factory(require("better-sqlite3"));
	else
		root["dot-webformumat"] = factory(root["better-sqlite3"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__1498__) => {
return 