package digital.steva.dot.webformumat

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.unit.dp
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.ui.FormView
import digital.steva.formumat.ui.PageNavigationView
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

@Composable
fun MainView(
    state: AppState,
    dispatcher: Dispatcher
) {
    val values =
        FormumatValues(
            state.formumatState.data,
            state.formumatState.dataSchema.typesByKey,
            state.formumatState.uiSchema.fieldsByKey,
            dispatcher
        )
    val coroutineScope = rememberCoroutineScope()

    BoxWithConstraints() {
        val wideView = constraints.maxWidth >= 1200
        val drawerState: DrawerState =
            rememberDrawerState(initialValue = if (wideView) DrawerValue.Open else DrawerValue.Closed)

        if (wideView) {
            Scaffold(
                topBar = {
                    Header(state, values, drawerState, wideView, dispatcher)
                }
            ) { paddingValues ->
                Row {
                    Column(
                        modifier = Modifier.width(IntrinsicSize.Max).widthIn(0.dp, 800.dp)
                            .padding(paddingValues),

                        ) {
                        if (drawerState.isOpen) {
                            ModalDrawerSheet(
                                drawerShape = RectangleShape
                            ) {
                                DrawerContent(
                                    state.formumatState,
                                    values,
                                    wideView,
                                    dispatcher,
                                    coroutineScope,
                                    drawerState
                                )
                            }
                        }
                    }
                    PageFormumat(state.formumatState, dispatcher, paddingValues)
                }
            }
        } else {
            ModalNavigationDrawer(
                drawerState = drawerState,
                drawerContent = {
                    ModalDrawerSheet(
                    ) {
                        DrawerContent(
                            state.formumatState,
                            values,
                            wideView,
                            dispatcher,
                            coroutineScope,
                            drawerState
                        )
                    }
                }
            ) {
                Scaffold(
                    topBar = { Header(state, values, drawerState, wideView, dispatcher) }
                ) { paddingValues ->
                    PageFormumat(state.formumatState, dispatcher, paddingValues)
                }
            }
        }
    }
}

@Composable
fun DrawerContent(
    formumatState: FormumatState,
    values: FormumatValues,
    wideView: Boolean,
    dispatch: Dispatcher,
    coroutineScope: CoroutineScope,
    drawerState: DrawerState,
) {
    Column(
        modifier = Modifier.verticalScroll(rememberScrollState()).padding(top = 8.dp, bottom = 8.dp)
    ) {
        PageNavigationView(formumatState.uiSchema.items,
            formumatState.dataSchema.typesByKey,
            values,
            formumatState.currentPage,
            dispatch,
            onPageSelected = {
                if (! wideView) {
                    coroutineScope.launch { drawerState.close() }
                }
            })
    }
}

@Composable
fun PageFormumat(
    formumatState: FormumatState,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(paddingValues = paddingValues),
        verticalArrangement = Arrangement.Top
    ) {
        Box(modifier = Modifier.padding(8.dp)) {
            FormView(formumatState.uiSchema, formumatState.dataSchema, formumatState, dispatch)
        }
    }
}

@Suppress("UNUSED_PARAMETER")
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun Header(
    state: AppState,
    values: FormumatValues,
    drawerState: DrawerState,
    wideView: Boolean,
    dispatcher: Dispatcher,
) {
    val coroutineScope = rememberCoroutineScope()
    val title = state.formumatState.uiSchema.title ?: ""
    TopAppBar(
        title = {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.padding(top = 12.dp, bottom = 12.dp)
            ) {
                Text(
                    text = title,
                    style = MaterialTheme.typography.headlineLarge,
                    modifier = Modifier.padding(start = 2.dp)
                )
            }
        },
        colors = TopAppBarDefaults.topAppBarColors(
            containerColor = MaterialTheme.colorScheme.primaryContainer
        ),
        actions = {
            IconButton(
                onClick = {
                    coroutineScope.launch {
                        if (drawerState.isClosed) {
                            drawerState.open()
                        } else {
                            drawerState.close()
                        }
                    }
                },
            ) {
                Icon(
                    imageVector = Icons.Filled.Menu,
                    contentDescription = ""
                )
            }
        }
    )
}
