package digital.steva.dot.webformumat

import digital.steva.dot.app.middlewares.createOtherStoresMiddlware
import digital.steva.formumat.redux.FormumatAction
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatStore
import digital.steva.formumat.redux.createFormumatState
import org.reduxkotlin.Store
import org.reduxkotlin.applyMiddleware
import org.reduxkotlin.threadsafe.createThreadSafeStore

data class AppState(
    val formumatState: FormumatState = createFormumatState(EMPTY_DATA_SCHEMA, EMPTY_UI_SCHEMA, EMPTY_VALUES),
    val zoomLevel: Float = 0f
)

open class AppAction

data class SetZoomLevel(val zoomLevel: Float) : AppAction()
data class DispatchFormumat(val formumatAction: FormumatAction) : AppAction()
data class SetFormumatState(val formumatState: FormumatState) : AppAction()
data class LoadFormumatDataSchema(val schema: String) : AppAction()
data class LoadFormumatUiSchema(val schema: String) : AppAction()
data class LoadFormumatValues(val values: String) : AppAction()
class ClearFormumat : AppAction()

fun createAppStore(formumatStore: FormumatStore, zoomLevel: Float): Store<AppState> {
    return createThreadSafeStore(
        ::mainReducer,
        AppState(formumatState = formumatStore.state, zoomLevel = zoomLevel),
        applyMiddleware(
            createOtherStoresMiddlware(formumatStore),
        )
    ).apply {
        formumatStore.subscribe {
            dispatch(SetFormumatState(formumatStore.state))
        }
    }
}
