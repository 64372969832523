package digital.steva.dot.app.middlewares

import co.touchlab.kermit.Logger
import digital.steva.dot.webformumat.*
import digital.steva.formumat.redux.*
import digital.steva.formumat.schema.parseDataSchema
import digital.steva.formumat.schema.parseUiSchema
import org.reduxkotlin.Store
import org.reduxkotlin.middleware

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
fun createOtherStoresMiddlware(formumatStore: Store<FormumatState>) =
    middleware<AppState> { store, next, action ->
        when (action) {
            is DispatchFormumat -> formumatStore.dispatch(action.formumatAction)
            is LoadFormumatDataSchema -> {
                try {
                    formumatStore.dispatch(SetDataSchema(parseDataSchema(action.schema)))
                } catch (e: Throwable) {
                    Logger.e { "Unable to parse data schema: ${e.message}" }
                }
            }

            is LoadFormumatUiSchema -> {
                try {
                    formumatStore.dispatch(SetUiSchema(parseUiSchema(action.schema)))
                } catch (e: Throwable) {
                    Logger.e { "Unable to parse ui schema: ${e.message}" }
                }
            }

            is LoadFormumatValues -> {
                try {
                    formumatStore.dispatch(SetValues(parseValues(action.values)))
                } catch (e: Throwable) {
                    Logger.e { "Unable to parse values: ${e.message}" }
                }
            }

            is ClearFormumat -> {
                formumatStore.dispatch(SetDataSchema(parseDataSchema(EMPTY_DATA_SCHEMA)))
                formumatStore.dispatch(SetUiSchema(parseUiSchema(EMPTY_UI_SCHEMA)))
                formumatStore.dispatch(SetValues(parseValues(EMPTY_VALUES)))
            }

            else -> next(action)
        }
    }
