package digital.steva.dot.webformumat

import androidx.compose.runtime.*
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import co.touchlab.kermit.Logger
import co.touchlab.kermit.Severity
import digital.steva.dot.webformumat.theme.AppTheme
import digital.steva.formumat.FormumatNonpublic
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.stringifyValues
import org.reduxkotlin.Store

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
@Composable
fun AppView(
    appStore: Store<AppState>,
    dispatcher: Dispatcher,
    onInitialized: () -> Unit = {},
    onDataChanged: (String) -> Unit = {}
) {
    @Suppress("UNUSED_VARIABLE") val configured by remember { mutableStateOf(FormumatNonpublic.configure()) }
    val store by remember { mutableStateOf(appStore) }
    var state by remember { mutableStateOf(store.state) }
    DisposableEffect(store) {
        Logger.setMinSeverity(Severity.Info)
        Logger.i { "Dot initialized" }
        val unsubscribe = store.subscribe {
            state = store.state
            onDataChanged(stringifyValues(state.formumatState.data))
        }
        onDispose { unsubscribe() }
    }
    LaunchedEffect(store) {
        onInitialized()
    }

    if (state.zoomLevel > 0f) {
        CompositionLocalProvider(
            LocalDensity provides Density(
                state.zoomLevel,
                1.0f
            )
        ) {
            AppTheme(useDarkTheme = false) {
                MainView(state, dispatcher)
            }
        }
    } else {
        AppTheme(useDarkTheme = false) {
            MainView(state, dispatcher)
        }
    }
}
