package digital.steva.formumat.`public`

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocale
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "30fa0f9a62d2ce3fbc31ceb20b45b1c8"

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    private val fallbackFileUrl: String =
        js("require(\"./localization/digitalstevaformumatpublic_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        SupportedLocale("de",
            js("require(\"./localization/digitalstevaformumatpublic_stringsJson_de.json\")") as
            String),
        ))

    public override val stringsLoader: RemoteJsStringLoader =
        RemoteJsStringLoader.Impl(supportedLocales = supportedLocales, fallbackFileUri =
        fallbackFileUrl)

    public actual val action_ok: StringResource = StringResource(key = "action_ok", loader =
        stringsLoader)

    public actual val action_cancel: StringResource = StringResource(key = "action_cancel", loader =
        stringsLoader)

    public actual val title_select_time: StringResource = StringResource(key = "title_select_time",
        loader = stringsLoader)

    public actual val error_no_floating_point_number: StringResource = StringResource(key =
        "error_no_floating_point_number", loader = stringsLoader)

    public actual override fun values(): List<StringResource> = listOf(action_ok, action_cancel,
        title_select_time, error_no_floating_point_number)
  }

  public actual object fonts : ResourceContainer<FontResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val fa_regular_400: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/fa_regular_400.ttf\")") as String, fontFamily = "fa_regular_400")

    public actual val fa_solid_900: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/fa_solid_900.ttf\")") as String, fontFamily = "fa_solid_900")

    public actual val fa_brands_400: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/fa_brands_400.ttf\")") as String, fontFamily = "fa_brands_400")

    public actual override fun values(): List<FontResource> = listOf(fa_regular_400, fa_solid_900,
        fa_brands_400)

    public fun addFontsToPage(): Unit {
      js("require(\"./fonts/digitalstevaformumatpublic-generated-declarations.css\")")
    }
  }
}
