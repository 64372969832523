package digital.steva.dot.webformumat

fun mainReducer(state: AppState, action: Any) =
    when (action) {
        is SetZoomLevel -> state.copy(zoomLevel = action.zoomLevel)

        is SetFormumatState -> state.copy(formumatState = action.formumatState)

        else -> state
    }
