package digital.steva.dot.webformumat

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "61940eac620db39591d40ad915811385"

  public actual object fonts : ResourceContainer<FontResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val barlow_blackitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-BlackItalic.ttf\")") as String, fontFamily =
        "barlow_blackitalic")

    public actual val barlow_extrabold: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraBold.ttf\")") as String, fontFamily = "barlow_extrabold")

    public actual val barlow_light: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Light.ttf\")") as String, fontFamily = "barlow_light")

    public actual val barlow_lightitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-LightItalic.ttf\")") as String, fontFamily =
        "barlow_lightitalic")

    public actual val barlow_thinitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ThinItalic.ttf\")") as String, fontFamily =
        "barlow_thinitalic")

    public actual val barlow_extralight: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraLight.ttf\")") as String, fontFamily =
        "barlow_extralight")

    public actual val barlow_extrabolditalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraBoldItalic.ttf\")") as String, fontFamily =
        "barlow_extrabolditalic")

    public actual val barlow_bold: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Bold.ttf\")") as String, fontFamily = "barlow_bold")

    public actual val barlow_italic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Italic.ttf\")") as String, fontFamily = "barlow_italic")

    public actual val barlow_semibold: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-SemiBold.ttf\")") as String, fontFamily = "barlow_semibold")

    public actual val barlow_black: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Black.ttf\")") as String, fontFamily = "barlow_black")

    public actual val barlow_medium: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Medium.ttf\")") as String, fontFamily = "barlow_medium")

    public actual val barlow_regular: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Regular.ttf\")") as String, fontFamily = "barlow_regular")

    public actual val barlow_bolditalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-BoldItalic.ttf\")") as String, fontFamily =
        "barlow_bolditalic")

    public actual val barlow_extralightitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraLightItalic.ttf\")") as String, fontFamily =
        "barlow_extralightitalic")

    public actual val barlow_mediumitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-MediumItalic.ttf\")") as String, fontFamily =
        "barlow_mediumitalic")

    public actual val barlow_semibolditalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-SemiBoldItalic.ttf\")") as String, fontFamily =
        "barlow_semibolditalic")

    public actual val barlow_thin: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Thin.ttf\")") as String, fontFamily = "barlow_thin")

    public actual override fun values(): List<FontResource> = listOf(barlow_blackitalic,
        barlow_extrabold, barlow_light, barlow_lightitalic, barlow_thinitalic, barlow_extralight,
        barlow_extrabolditalic, barlow_bold, barlow_italic, barlow_semibold, barlow_black,
        barlow_medium, barlow_regular, barlow_bolditalic, barlow_extralightitalic,
        barlow_mediumitalic, barlow_semibolditalic, barlow_thin)

    public fun addFontsToPage(): Unit {
      js("require(\"./fonts/digitalstevadotwebformumat-generated-declarations.css\")")
    }
  }
}
