package digital.steva.formumat.nonpublic

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocale
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "35f3ef7a4642458a9eca62d79c0f7961"

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    private val fallbackFileUrl: String =
        js("require(\"./localization/digitalstevaformumatnonpublic_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        SupportedLocale("de",
            js("require(\"./localization/digitalstevaformumatnonpublic_stringsJson_de.json\")") as
            String),
        ))

    public override val stringsLoader: RemoteJsStringLoader =
        RemoteJsStringLoader.Impl(supportedLocales = supportedLocales, fallbackFileUri =
        fallbackFileUrl)

    public actual val action_settings: StringResource = StringResource(key = "action_settings",
        loader = stringsLoader)

    public actual val action_cancel: StringResource = StringResource(key = "action_cancel", loader =
        stringsLoader)

    public actual val dialog_camera_picker_permission_title: StringResource = StringResource(key =
        "dialog_camera_picker_permission_title", loader = stringsLoader)

    public actual val dialog_camera_picker_permission_message: StringResource = StringResource(key =
        "dialog_camera_picker_permission_message", loader = stringsLoader)

    public actual override fun values(): List<StringResource> = listOf(action_settings,
        action_cancel, dialog_camera_picker_permission_title,
        dialog_camera_picker_permission_message)
  }
}
