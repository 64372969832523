package digital.steva.dot.webformumat.theme

import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import dev.icerock.moko.resources.compose.asFont
import digital.steva.dot.webformumat.MR

@Composable
fun createFont() = MR.fonts.barlow_light.asFont()?.let { FontFamily(it) }

fun createTypo(barlowLight: FontFamily?): Typography {

    return Typography(
        headlineLarge = TextStyle(
            fontWeight = FontWeight.SemiBold,
            fontSize = 20.sp,
            letterSpacing = 0.sp,
            fontFamily = barlowLight
        ),
        headlineMedium = TextStyle(
            fontWeight = FontWeight.SemiBold,
            fontSize = 18.sp,
            letterSpacing = 0.sp,
            fontFamily = barlowLight
        ),
        headlineSmall = TextStyle(
            fontWeight = FontWeight.SemiBold,
            fontSize = 16.sp,
            letterSpacing = 0.sp,
            fontFamily = barlowLight
        ),
        displayLarge = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 17.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        displayMedium = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 16.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        displaySmall = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 15.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        titleLarge = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 17.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        titleMedium = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 16.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        titleSmall = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 15.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        bodyLarge = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 17.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        bodyMedium = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 16.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        bodySmall = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 15.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        labelLarge = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 18.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        labelMedium = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 17.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        ),
        labelSmall = TextStyle(
            fontWeight = FontWeight.Normal,
            fontSize = 16.sp,
            letterSpacing = 0.25.sp,
            fontFamily = barlowLight
        )
    )
}
