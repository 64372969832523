package digital.steva.dot.webformumat

import Base64.decodeFromBase64
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import co.touchlab.kermit.*
import digital.steva.formumat.redux.*
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.MessageEvent
import kotlin.reflect.typeOf

//language=JSON
val EMPTY_DATA_SCHEMA = """{
  "type": "dataSchema",
  "properties": {
  }
}"""

//language=JSON
val EMPTY_UI_SCHEMA = """{
  "type": "uiSchema",
  "items": [
  ]
}"""

//language=JSON
val EMPTY_VALUES = """{
}
"""

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        Logger.setLogWriters(PostMessageLogWriter(NoTagFormatter))
        Logger.setMinSeverity(Severity.Debug)

        val zoomLevel = window.devicePixelRatio.toFloat() * 0.85f
        val formumatStore = createFormumatStore(EMPTY_DATA_SCHEMA, EMPTY_UI_SCHEMA, EMPTY_VALUES)
        val appStore = createAppStore(formumatStore, zoomLevel)
        val dispatcher: Dispatcher = { action ->
            when (action) {
                is FormumatAction -> appStore.dispatch(DispatchFormumat(action))
                else -> appStore.dispatch(action)
            }
        }

        var initialized = false

        window.addEventListener("message", { event ->
            val messageEvent = event as MessageEvent
            try {
                val message =
                    Json.decodeFromString<Map<String, String>>(messageEvent.data.toString())
                when (message["action"]) {
                    "CheckInitialized" -> {
                        if (initialized) {
                            CoroutineScope(Dispatchers.Default).launch {
                                window.parent.postMessage(
                                    Json.encodeToString(mapOf("type" to "Initialized")),
                                    "*"
                                )
                            }
                        }
                    }

                    "SetDataSchema" -> {
                        dispatcher(LoadFormumatDataSchema(message["schema"]!!))
                    }

                    "SetUiSchema" -> {
                        dispatcher(LoadFormumatUiSchema(message["schema"]!!))
                    }

                    "SetValues" -> {
                        dispatcher(LoadFormumatValues(message["values"]!!))
                    }

                    "SetZoomLevel" -> {
                        dispatcher(SetZoomLevel(message["zoomLevel"]?.toFloatOrNull() ?: 1.0f))
                    }

                    "SetFile" -> {
                        dispatcher(StorageSetValue(message["key"].toString(), message["data"].toString()
                            .decodeFromBase64(), typeOf<ByteArray>()))
                    }
                }
            } catch (e: Throwable) {
            }
        })

        CanvasBasedWindow("dot.") {
            AppView(
                appStore,
                dispatcher,
                onInitialized = {
                    if (!initialized) {
                        initialized = true
                        window.parent.postMessage(
                            Json.encodeToString(mapOf("type" to "Initialized")),
                            "*"
                        )
                    }
                },
                onDataChanged = { data ->
                    window.parent.postMessage(
                        Json.encodeToString(mapOf("type" to "DataChanged", "data" to data)),
                        "*"
                    )
                }
            )
        }
    }
}

data class PostMessageLogWriter(
    val messageStringFormatter: MessageStringFormatter,
) : LogWriter() {
    override fun log(severity: Severity, message: String, tag: String, throwable: Throwable?) {
        var output = messageStringFormatter.formatMessage(
            null,
            Tag(tag),
            Message(message)
        ) // Ignore severity. It is part of the console API.
        throwable?.let {
            output += " ${it.stackTraceToString()}"
        }
        CoroutineScope(Dispatchers.Default).launch {
            window.parent.postMessage(
                Json.encodeToString(
                    mapOf(
                        "type" to "Log",
                        "severity" to severity.toString(),
                        "message" to output.toString()
                    )
                ),
                "*"
            )
        }
    }
}
